import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { iContact } from "../model/contact.model";

@Injectable({
    providedIn: 'root'
})

export class ContactsService
{
    constructor(private http: HttpClient) 
    { 
        // Nothing to do
    }

    getContacts(): Observable<iContact[]>
    {
        return this.http.get<iContact[]>('api/contacts');
    }

}