import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { iPaymentTerm } from "../model/payment-term.model";


@Injectable({
    providedIn: 'root'
})

export class InvoiceService
{
    constructor(private http: HttpClient) 
    { 
        // Nothing to do
    }

    getPaymentTerms(): Observable<iPaymentTerm[]>
    {
        return this.http.get<iPaymentTerm[]>(`api/paymentTerms/`);
    }
}