import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { iClient } from '../model/client.model';
import { ClientsService } from '../services/client.service';
import { TimezoneService } from '../timezone.service';
import { InvoiceService } from '../services/invoice.service';
import { iPaymentTerm } from '../model/payment-term.model';
import { iSalesTax } from '../model/sales-tax.model';
import { SalesTaxService } from '../services/sales-tax.service';
import { v4 as uuidv4 } from 'uuid';
import { AuthService } from '../auth.service';
import { BehaviorSubject, forkJoin } from 'rxjs';
import { HttpEventType, HttpRequest, HttpResponse } from '@angular/common/http';
import { MessageService } from 'primeng/api';
import { UserService } from '../user.service';

@Component({
  selector: 'app-client-primeng',
  templateUrl: './client-primeng.component.html',
  styleUrls: ['./client-primeng.component.css']
})
export class ClientPrimengComponent implements OnInit {
  clientForm: FormGroup;
  clientMergeForm: FormGroup;
  clientData: iClient;
  paymentTerms: iPaymentTerm[] = [];
  timeZones = [];
  mergeClients: iClient[];
  filteredMergeClients: iClient[] = [];
  formErrors: { [key: string]: string } = {};
  isReadOnly = false;
  isNew = false;
  dataLoaded = false;
  salesTaxJurisdictions: iSalesTax[] = [];
  phoneExampleText = '(123) 456-7890';
  displayDialog: boolean = false;
  anyError = false;
  uploadStatus: string | null = null;
  percentDone: number | null = null;
  error: string | null = null;
  http: any;

  constructor(
    private fb: FormBuilder,
    private clientService: ClientsService,
    private route: ActivatedRoute,
    private timeZoneService: TimezoneService,
    private invoiceService: InvoiceService,
    private salesTaxService: SalesTaxService,
    private messageService: MessageService,
    private authService: AuthService,
  ) {}

  ngOnInit()
  {
    this.route.queryParams.subscribe(params => {
      const clientUuid = params['id'];
      this.isNew = !clientUuid;

      this.initClientForm();
      this.initializeClientMergeForm();
      this.loadDropdownData(clientUuid);
    });
  }

  initializeClientMergeForm()
  {
    this.clientMergeForm = this.fb.group({
      companyName: [{ value: '', disabled: true }, Validators.required],
      mergeCompanyName: ['', Validators.required],
      confirmInput: ['', Validators.required]
    });
  }
  

  initClientForm()
  {
    this.clientForm = this.fb.group({
      companyLogo: [null],
      webAddress: [{ value: '', disabled: this.isReadOnly }, [Validators.pattern(/^www\..+/)]],
      address: [''],
      name: [{ value: '', disabled: this.isReadOnly }, Validators.required],
      defaultTimeZone: [{ value: '', disabled: this.isReadOnly }],
      phone: [{ value: '', disabled: this.isReadOnly }],
      fax: [{ value: '', disabled: this.isReadOnly }],
      invoiceEmailAddress: [{ value: '', disabled: this.isReadOnly }, [Validators.email]],
      defaultPaymentTermId: [{ value: '', disabled: this.isReadOnly }],
      jurisdictionName: [{ value: '', disabled: this.isReadOnly }],
      internalBillingNotes: ['']
    });
  }

  loadDropdownData(clientUuid?: string)
  {
    forkJoin({
      timeZones: this.timeZoneService.getTimezones(),
      paymentTerms: this.invoiceService.getPaymentTerms(),
      salesTaxJurisdictions: this.salesTaxService.getSalesTaxJurisdiction(),
      clients: this.clientService.getClientele(this.authService.companyId, 'client')
    }).subscribe({
      next: ({ timeZones, paymentTerms, salesTaxJurisdictions, clients }) => {
        this.mergeClients = clients;
        this.timeZones = timeZones;
        this.paymentTerms = paymentTerms;
        this.salesTaxJurisdictions = salesTaxJurisdictions;
    
        if (!this.isNew && clientUuid)
        {
          this.loadClientData(clientUuid);
        } else
        {
          this.dataLoaded = true;
        }
      },
      error: (err) => {
        console.error('Error loading dropdown data:', err);
      }
    });   
  }

  loadClientData(clientUuid: string)
  {
    this.clientService.getClient(clientUuid).subscribe({
      next: (data) => {
        this.clientData = data;
        this.patchClientData(data);
        this.dataLoaded = true;
      },
      error: (err) => {
        console.error('Error fetching client data:', err);
      }
    });
  }

  patchClientData(clientData: iClient)
  {
    const existingClientData = {
      companyLogo: clientData.companyLogo ?? null,
      webAddress: clientData.webAddress ?? '',
      address: clientData.address ?? '',
      name: clientData.name ?? '',
      defaultTimeZone: this.findDropdownOption(this.timeZones, clientData.timeZone, 'display_value'),
      phone: clientData.phoneNumber ?? '',
      fax: clientData.faxNumber ?? '',
      invoiceEmailAddress: clientData.email ?? '',
      defaultPaymentTermId: this.findDropdownOption(this.paymentTerms, clientData.paymentTerms, 'id'),
      jurisdictionName: this.findDropdownOption(this.salesTaxJurisdictions, clientData.defualtSalesTaxId, 'id'),
      internalBillingNotes: clientData.internalNotes ?? ''
    };

    this.clientForm.patchValue(existingClientData);
    this.setFormControlsState(false);
  }

  get webAddress()
  {
    return this.clientForm.get('webAddress');
  }

  get email()
  {
    return this.clientForm.get('invoiceEmailAddress');
  }

  patchClientMergeData(clientData: iClient)
  {
    const existingClientMergeData = {
      companyName: clientData.name ?? '',
      mergeCompanyName: '',
      confirmInput: ''
    };

    this.clientMergeForm.patchValue(existingClientMergeData);
  }

  findDropdownOption(array: any[], value: any, key: string)
  {
    return array && array.length > 0 ? array.find(option => option[key] === value) ?? null : null;
  }

  onSubmit()
  {
    if (this.isNew)
    {
      this.create();
    } else
    {
      this.update();
    }
  }

  update()
  {
    if (this.clientForm.valid)
    {
      const selectedPaymentTerm = this.clientForm.value.defaultPaymentTermId;
      const selectedJurisdiction = this.clientForm.value.jurisdictionName;
      const selectedTimeZone = this.clientForm.value.defaultTimeZone;

      const clientData: iClient = {
        id: this.isNew ? uuidv4() : this.clientData.id,
        name: this.clientForm.value.name,
        address: this.clientForm.value.address,
        companyLogo: this.clientForm.value.companyLogo,
        email: this.clientForm.value.invoiceEmailAddress,
        phoneNumber: this.clientForm.value.phone,
        defualtSalesTaxId: selectedJurisdiction?.id ?? null,
        webAddress: this.clientForm.value.webAddress,
        timeZone: selectedTimeZone?.display_value ?? null,
        faxNumber: this.clientForm.value.fax,
        paymentTerms: selectedPaymentTerm?.id ?? null,
        internalNotes: this.clientForm.value.internalBillingNotes
      };

      this.clientService.updateClient(clientData).subscribe({
        next: response => console.log("Client saved successfully", response),
        error: error => console.error("Error saving client:", error),
        complete: () => window.history.back()
      });
    } else
    {
      this.anyError = true;
      this.formErrors = this.getFormValidationErrors();
    }
  }

  create()
  {
    if (this.clientForm.valid)
    {
      const selectedPaymentTerm = this.clientForm.value.defaultPaymentTermId;
      const selectedJurisdiction = this.clientForm.value.jurisdictionName;
      const selectedTimeZone = this.clientForm.value.defaultTimeZone;
      const parentCompanyUuid = this.authService.companyId;

      const clientData: iClient = {
        parentCompanyUuid: parentCompanyUuid,
        name: this.clientForm.value.name,
        address: this.clientForm.value.address,
        companyLogo: this.clientForm.value.companyLogo,
        email: this.clientForm.value.invoiceEmailAddress,
        phoneNumber: this.clientForm.value.phone,
        defualtSalesTaxId: selectedJurisdiction?.id ?? null,
        webAddress: this.clientForm.value.webAddress,
        timeZone: selectedTimeZone?.display_value ?? null,
        faxNumber: this.clientForm.value.fax,
        paymentTerms: selectedPaymentTerm?.id ?? null,
        internalNotes: this.clientForm.value.internalBillingNotes
      };

      this.clientService.createClient(clientData).subscribe({
        next: response => console.log("Client saved successfully", response),
        error: error => console.error("Error saving client:", error),
        complete: () => window.history.back()
      });
    } else
    {
      this.anyError = true;
      this.formErrors = this.getFormValidationErrors();
    }
  }

  setFormControlsState(isReadOnly: boolean)
  {
    Object.keys(this.clientForm.controls).forEach(controlName => {
      if (isReadOnly)
      {
        this.clientForm.get(controlName)?.disable();
      } else
      {
        this.clientForm.get(controlName)?.enable();
      }
    });
  }

  getFormValidationErrors()
  {
    const errors: { [key: string]: string } = {};
    Object.keys(this.clientForm.controls).forEach((key) => {
      const controlErrors = this.clientForm.get(key)?.errors;
      if (controlErrors)
      {
        errors[key] = Object.keys(controlErrors)
          .map((error) => {
            switch (error)
            {
              case 'required':
                return `${key} is required.`;
              case 'pattern':
                return `${key} format is invalid.`;
              case 'email':
                return `${key} must be a valid email address.`;
              default:
                return '';
            }
          })
          .join(' ');
      }
    });
    return errors;
  }

  back() {
    window.history.back();
  }

  uploadFile(event: any)
  {
    const file = event.files[0];
    if (!file)
    {
      this.error = "Please select a file to attach.";
      return;
    }

    this.uploadStatus = "Uploading";
    this.percentDone = 0;

    this.clientService.uploadProfilePicture(file).subscribe({
      next: (event: any) => {
        if (event.type === HttpEventType.UploadProgress && event.total)
        {
          this.percentDone = Math.round(100 * event.loaded / event.total);
          this.uploadStatus = this.percentDone === 100 ? "Processing" : "Uploading";
        } else if (event instanceof HttpResponse)
          {
          if (event.body?.success)
          {
            this.error = null;
            this.clientForm.get('companyLogo')?.setValue(event.body.uuid); // Assuming response includes UUID
            this.uploadStatus = "Complete";
          } else
          {
            this.error = "File could not be uploaded: " + (event.body?.message || "Unknown error.");
          }
          this.percentDone = null;
        }
      },
      error: (err) => {
        this.error = "Upload failed. Please try again.";
        this.percentDone = null;
        this.uploadStatus = null;
      }
    });
  }

  get isMergeDisabled(): boolean
  {
    const companyName = this.clientMergeForm.get('companyName')?.value || '';
    const mergeCompanyName = this.clientMergeForm.get('mergeCompanyName')?.value.name || '';
    const confirmInput = this.clientMergeForm.get('confirmInput')?.value || '';
  
    return (
      confirmInput.toLowerCase() !== 'yes' ||
      this.clientMergeForm.invalid ||
      companyName.trim().toLowerCase() === mergeCompanyName.trim().toLowerCase()
    );
  }

  searchMergeClients(event: any)
  {
    const query = event.query.toLowerCase();
    this.filteredMergeClients = this.mergeClients.filter(client =>
      client.chargebeeCustomerId === null && client.name.toLowerCase().includes(query)
    );
  }
  

  onMergeSubmit()
  {
    if (this.clientMergeForm.valid)
    {
      const mergeCompanyName = this.clientMergeForm.get('mergeCompanyName').value;
      const userEmail = this.authService.username;
      const userCompanyUuid = this.authService.companyId;
      const keepCompanyUuid = this.clientData.id;
      const removeCompanyUuid = mergeCompanyName.id;

      const matchedCompany = this.mergeClients.find(
          company => company.id === mergeCompanyName.id
      );

      if (matchedCompany)
      {
        console.log('Merging with company:', matchedCompany);

        this.clientService.clientMerge(
          userEmail,
          userCompanyUuid,
          keepCompanyUuid,
          removeCompanyUuid
        ).subscribe({
            next: (response) => {
              if (response.success)
              {
                this.messageService.add({
                  severity: 'success',
                  summary: 'Merge Successful',
                  detail: 'The companies were successfully merged.',
                  life: 3000
                });
                this.displayDialog = false;
              } else
              {
                this.messageService.add({
                    severity: 'error',
                    summary: 'Merge Failed',
                    detail: response.message || 'An error occurred during the merge.',
                    life: 3000
                });
                console.error('Merge failed:', response.message);
              }
            },
            error: (error) => {
              this.messageService.add({
                severity: 'error',
                summary: 'Error',
                detail: 'An unexpected error occurred during the merge.',
                life: 3000
              });
              console.error('Error during client merge:', error);
            }
        });
      } else
      {
        this.clientMergeForm.get('mergeCompanyName').setErrors({ noMatch: true });
        this.messageService.add({
          severity: 'warn',
          summary: 'No Match Found',
          detail: 'No matching company was found for the entered name.',
          life: 3000
        });
        console.log('No matching company found for', mergeCompanyName);
      }
    } else
    {
      this.messageService.add({
        severity: 'warn',
        summary: 'Invalid Form',
        detail: 'Please correct the errors in the form before submitting.',
        life: 3000
      });
      console.log('Form is invalid.');
    }
  }
}
