import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { AuthGuard } from './auth.guard';
import { UserListComponent } from './user-list/user-list.component';
import { AboutComponent } from './about/about.component';
import { CompanySettingsComponent } from './company-settings/company-settings.component';
import { AccountCreateComponent } from './account-create/account-create.component';
import { OrderCreateComponent } from './order-create/order-create.component';
import { OrderDocumentsComponent } from './order-documents/order-documents.component';
import { OrderNotificationsComponent } from './order-notifications/order-notifications.component';
import { OrderReviewComponent } from './order-review/order-review.component';
import { OrderServicesEntityComponent } from './order-services-entity/order-services-entity.component';
import { OrderServicesIndividualComponent } from './order-services-individual/order-services-individual.component';
import { OrderServicesPropertyComponent } from './order-services-property/order-services-property.component';
import { AccountChoosePlanComponent } from './account-choose-plan/account-choose-plan.component';
import { AccountReviewComponent } from './account-review/account-review.component';
import { ResearcherToolsComponent } from './researcher-tools/researcher-tools.component';
import { ResearcherToolsAdvancedComponent } from './researcher-tools-advanced/researcher-tools-advanced.component';
import { WelcomeComponent } from './welcome/welcome.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { TermsOfUseComponent } from './terms-of-use/terms-of-use.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { ClientSearchComponent } from './client-search/client-search.component';
import { SearcherDashboardComponent } from './searcher-dashboard/searcher-dashboard.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { ForgotPasswordResetComponent } from './forgot-password-reset/forgot-password-reset.component';
import { VerifyEmailComponent } from './verify-email/verify-email.component';
import { SystemErrorComponent } from './system-error/system-error.component';
import { AccountChooseCompanyTypeComponent } from './account-choose-company-type/account-choose-company-type.component';
import { InitialPageComponent } from './initial-page/initial-page.component';
import { SecurityQuestionsSetComponent } from './security-questions-set/security-questions-set.component';
import { SecurityQuestionsChallengeComponent } from './security-questions-challenge/security-questions-challenge.component';
import { OrderSheetHtmlComponent } from './order-sheet-html/order-sheet-html.component';
import { OrderAssessmentInfoComponent } from './order-assessment-info/order-assessment-info.component';
import { SearcherOrderEditComponent } from './searcher-order-edit/searcher-order-edit.component';
import { SearcherOrderEditSblComponent } from './searcher-order-edit-sbl/searcher-order-edit-sbl.component';
import { InvoiceViewComponent } from './invoice-view/invoice-view.component';
import { InvoiceEditComponent } from './invoice-edit/invoice-edit.component';
import { InvoiceListComponent } from './invoice-list/invoice-list.component';
import { InvoiceReportComponent } from './invoice-report/invoice-report.component';
import { InvoiceConfigureComponent } from './invoice-configure/invoice-configure.component';
import { InvoiceHistoryComponent } from './invoice-history/invoice-history.component';
import { ProviderPortalComponent } from './provider-portal/provider-portal.component';
import { DashboardSearchMyComponent } from './dashboard-search-my/dashboard-search-my.component';
import { DashboardSearchAllOpenComponent } from './dashboard-search-all-open/dashboard-search-all-open.component';
import { DashboardOrderAllOpenComponent } from './dashboard-order-all-open/dashboard-order-all-open.component';
import { DashboardOrderOverdueComponent } from './dashboard-order-overdue/dashboard-order-overdue.component';
import { DashboardOrderOnHoldComponent } from './dashboard-order-on-hold/dashboard-order-on-hold.component';
import { DashboardSearchOverdueComponent } from './dashboard-search-overdue/dashboard-search-overdue.component';
import { RunSheetHtmlComponent } from './run-sheet-html/run-sheet-html.component';
import { TaxSearchComponent } from './tax-search/tax-search.component';
import { DashboardOrderToBeInvoicedComponent } from './dashboard-order-to-be-invoiced/dashboard-order-to-be-invoiced.component';
import { RunSheetDataEntryComponent } from './run-sheet-data-entry/run-sheet-data-entry.component';
import { DashboardSearchCompletedComponent } from './dashboard-search-completed/dashboard-search-completed.component';
import { DocumentDownloadComponent } from './document-download/document-download.component';
import { UserFeedbackComponent } from './user-feedback/user-feedback.component';
import { UserFeedbackConfirmationComponent } from './user-feedback-confirmation/user-feedback-confirmation.component';
import { QualiaDashboardComponent } from './qualia-dashboard/qualia-dashboard.component';
import { AgentDashboardComponent } from './agent-dashboard/agent-dashboard.component';
import { DashboardOrderCancelledComponent } from './dashboard-order-cancelled/dashboard-order-cancelled.component';
import { CustomerCompanyComponent } from './customer-company/customer-company.component';
import { UtilityReportComponent } from './utility-report/utility-report.component';
import { DashboardSearchPausedComponent } from './dashboard-search-paused/dashboard-search-paused.component';
import { DocumentSearchComponent } from './document-search/document-search.component';
import { VendorsComponent } from './vendors/vendors.component';
import { ClientsComponent } from './clients/clients.component';
import { ContactsComponent } from './contacts/contacts.component';
import { ProductsComponent } from './products/products.component';
import { ClientComponent } from './client/client.component';
import { ContactComponent } from './contact/contact.component';
import { ProductComponent } from './product/product.component';
import { VendorComponent } from './vendor/vendor.component';
import { DashboardSearchInProgressComponent } from './dashboard-search-in-progress/dashboard-search-in-progress.component';
import { DashboardSearchTodoComponent } from './dashboard-search-todo/dashboard-search-todo.component';
import { DashboardSearchTypingComponent } from './dashboard-search-typing/dashboard-search-typing.component';
import { DashboardSearchWaitingComponent } from './dashboard-search-waiting/dashboard-search-waiting.component';
import { DashboardOrderCompletedComponent } from './dashboard-order-completed/dashboard-order-completed.component';
import { DashboardOrderInProgressComponent } from './dashboard-order-in-progress/dashboard-order-in-progress.component';
import { DashboardOrderNewComponent } from './dashboard-order-new/dashboard-order-new.component';
import { ArReportComponent } from './ar-report/ar-report.component';
import { OverdueReportComponent } from './overdue-report/overdue-report.component';
import { SearchSearchComponent } from './search-search/search-search.component';
import { SearchSummaryComponent } from './search-summary/search-summary.component';
import { InvoiceAllComponent } from './invoice-all/invoice-all.component';
import { InvoiceOpenComponent } from './invoice-open/invoice-open.component';
import { TeamsComponent } from './teams/teams.component';
import { TeamComponent } from './team/team.component';
import { UserResetPasswordComponent } from './user-reset-password/user-reset-password.component';
import { UserComponent } from './user/user.component';
import { ButtonDemoComponent } from './button-demo/button-demo.component';
import { TaxProfileEditComponent } from './tax-profile-edit/tax-profile-edit.component';
import { AgentOrderDownloadComponent } from './agent-order-download/agent-order-download.component';
import { SysCountyComponent } from './sys-county/sys-county.component';
import { SysMunicipalityComponent } from './sys-municipality/sys-municipality.component';
import { SysSchoolComponent } from './sys-school/sys-school.component';
import { OrderDownloadMatComponent } from './agent/order-download-mat/order-download-mat.component';
import { AgentDashboardMatComponent } from './agent/agent-dashboard-mat/agent-dashboard-mat.component';
import { DocumentTableComponent } from './document-table/document-table.component';
import { ClientsPrimengComponent } from './clients-primeng/clients-primeng.component';
import { ClientPrimengComponent } from './client-primeng/client-primeng.component';
import { ContactsPrimengComponent } from './contacts-primeng/contacts-primeng.component';

const routes: Routes = [
    { path: '', redirectTo: '/initial-page', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'initial-page', component: InitialPageComponent, canActivate: [AuthGuard] },
    { path: 'account-choose-company-type', component: AccountChooseCompanyTypeComponent },
    { path: 'account-choose-plan', component: AccountChoosePlanComponent },
    { path: 'account-create', component: AccountCreateComponent },
    { path: 'account-review', component: AccountReviewComponent },
    { path: 'users', component: UserListComponent, canActivate: [AuthGuard] },
    { path: 'help', component: ContactUsComponent, canActivate: [AuthGuard] },
    { path: 'about', component: AboutComponent, canActivate: [AuthGuard] },
    { path: 'company-settings', component: CompanySettingsComponent, canActivate: [AuthGuard] },
    { path: 'order-create', component: OrderCreateComponent, canActivate: [AuthGuard] },
    { path: 'order-documents', component: OrderDocumentsComponent, canActivate: [AuthGuard] },
    { path: 'order-notifications', component: OrderNotificationsComponent, canActivate: [AuthGuard] },
    { path: 'order-review', component: OrderReviewComponent, canActivate: [AuthGuard] },
    { path: 'order-services-entity', component: OrderServicesEntityComponent, canActivate: [AuthGuard] },
    { path: 'order-services-individual', component: OrderServicesIndividualComponent, canActivate: [AuthGuard] },
    { path: 'order-services-property', component: OrderServicesPropertyComponent, canActivate: [AuthGuard] },
    { path: 'researcher-tools', component: ResearcherToolsComponent, canActivate: [AuthGuard] },
    { path: 'researcher-tools-advanced', component: ResearcherToolsAdvancedComponent, canActivate: [AuthGuard] },
    { path: 'welcome', component: WelcomeComponent, canActivate: [AuthGuard] },
    { path: 'contact-us', component: ContactUsComponent },
    { path: 'forgot-password', component: ForgotPasswordComponent },
    { path: 'reset-password', component: ForgotPasswordResetComponent },
    { path: 'terms-of-use', component: TermsOfUseComponent },
    { path: 'privacy-policy', component: PrivacyPolicyComponent },
    { path: 'agent-dashboard', component: AgentDashboardComponent, canActivate: [AuthGuard] },
    { path: 'client-search', component: ClientSearchComponent, canActivate: [AuthGuard] },
    { path: 'provider-dashboard', component: ProviderPortalComponent, canActivate: [AuthGuard] },
    { path: 'searcher-dashboard', component: SearcherDashboardComponent, canActivate: [AuthGuard] },
    { path: 'security-questions-set', component: SecurityQuestionsSetComponent, canActivate: [AuthGuard] },
    { path: 'security-questions-challenge', component: SecurityQuestionsChallengeComponent },
    { path: 'verify-email', component: VerifyEmailComponent },
    { path: 'system-error', component: SystemErrorComponent },
    { path: 'order-information', component: CompanySettingsComponent, canActivate: [AuthGuard] },
    { path: 'order-sheet-html', component: OrderSheetHtmlComponent, canActivate: [AuthGuard] },
    { path: 'order-assessment-info', component: OrderAssessmentInfoComponent, canActivate: [AuthGuard] },
    { path: 'searcher-order-edit', component: SearcherOrderEditComponent, canActivate: [AuthGuard] },
    { path: 'searcher-order-edit-sbl', component: SearcherOrderEditSblComponent, canActivate: [AuthGuard] },
    { path: 'invoice-edit', component: InvoiceEditComponent, canActivate: [AuthGuard] },
    { path: 'invoice-list', component: InvoiceListComponent, canActivate: [AuthGuard] },
    { path: 'invoice-view', component: InvoiceViewComponent, canActivate: [AuthGuard] },
    { path: 'invoice-report', component: InvoiceReportComponent, canActivate: [AuthGuard] },
    { path: 'invoice-configure', component: InvoiceConfigureComponent, canActivate: [AuthGuard] },
    { path: 'invoice-history', component: InvoiceHistoryComponent, canActivate: [AuthGuard] },
    { path: 'dashboard-search-my', component: DashboardSearchMyComponent, canActivate: [AuthGuard] },
    { path: 'dashboard-search-all-open', component: DashboardSearchAllOpenComponent, canActivate: [AuthGuard] },
    { path: 'dashboard-search-overdue', component: DashboardSearchOverdueComponent, canActivate: [AuthGuard] },
    { path: 'dashboard-search-completed', component: DashboardSearchCompletedComponent, canActivate: [AuthGuard] },
    { path: 'dashboard-search-paused', component: DashboardSearchPausedComponent, canActivate: [AuthGuard] },
    { path: 'dashboard-order-all-open', component: DashboardOrderAllOpenComponent, canActivate: [AuthGuard] },
    { path: 'dashboard-order-overdue', component: DashboardOrderOverdueComponent, canActivate: [AuthGuard] },
    { path: 'dashboard-order-on-hold', component: DashboardOrderOnHoldComponent, canActivate: [AuthGuard] },
    { path: 'dashboard-order-to-be-invoiced', component: DashboardOrderToBeInvoicedComponent, canActivate: [AuthGuard] },
    { path: 'dashboard-order-cancelled', component: DashboardOrderCancelledComponent, canActivate: [AuthGuard] },
    { path: 'run-sheet-html', component: RunSheetHtmlComponent, canActivate: [AuthGuard] },
    { path: 'tax-search', component: TaxSearchComponent, canActivate: [AuthGuard] },
    { path: 'order-search', component: ClientSearchComponent, canActivate: [AuthGuard] },
    { path: 'document-search', component: DocumentSearchComponent, canActivate: [AuthGuard] },
    { path: 'run-sheet-data-entry', component: RunSheetDataEntryComponent, canActivate: [AuthGuard] },
    { path: 'document-download', component: DocumentDownloadComponent },
    { path: 'user-feedback', component: UserFeedbackComponent, canActivate: [AuthGuard] },
    { path: 'user-feedback-confirmation', component: UserFeedbackConfirmationComponent, canActivate: [AuthGuard] },
    { path: 'qualia-dashboard', component: QualiaDashboardComponent, canActivate: [AuthGuard] },
    { path: 'qualia-dashboard-ready', component: QualiaDashboardComponent, canActivate: [AuthGuard] },
    { path: 'qualia-dashboard-new', component: QualiaDashboardComponent, canActivate: [AuthGuard] },
    { path: 'qualia-dashboard-in-progress', component: QualiaDashboardComponent, canActivate: [AuthGuard] },
    { path: 'qualia-dashboard-submitted', component: QualiaDashboardComponent, canActivate: [AuthGuard] },
    { path: 'qualia-dashboard-cancelled', component: QualiaDashboardComponent, canActivate: [AuthGuard] },
    { path: 'qualia-dashboard-errored', component: QualiaDashboardComponent, canActivate: [AuthGuard] },
    { path: 'customer-company', component: CustomerCompanyComponent, canActivate: [AuthGuard] },
    { path: 'utility-report', component: UtilityReportComponent, canActivate: [AuthGuard] },
    { path: 'clients', component: ClientsPrimengComponent, canActivate: [AuthGuard] },
    { path: 'client', component: ClientPrimengComponent, canActivate: [AuthGuard] },
    { path: 'contacts', component: ContactsPrimengComponent, canActivate: [AuthGuard] },
    { path: 'contact', component: ContactComponent, canActivate: [AuthGuard] },
    { path: 'products', component: ProductsComponent, canActivate: [AuthGuard] },
    { path: 'product', component: ProductComponent, canActivate: [AuthGuard] },
    { path: 'vendors', component: VendorsComponent, canActivate: [AuthGuard] },
    { path: 'vendor', component: VendorComponent, canActivate: [AuthGuard] },
    { path: 'dashboard-search-todo', component: DashboardSearchTodoComponent, canActivate: [AuthGuard] },
    { path: 'dashboard-search-in-progress', component: DashboardSearchInProgressComponent, canActivate: [AuthGuard] },
    { path: 'dashboard-search-typing', component: DashboardSearchTypingComponent, canActivate: [AuthGuard] },
    { path: 'dashboard-search-waiting', component: DashboardSearchWaitingComponent, canActivate: [AuthGuard] },
    { path: 'dashboard-order-new', component: DashboardOrderNewComponent, canActivate: [AuthGuard] },
    { path: 'dashboard-order-in-progress', component: DashboardOrderInProgressComponent, canActivate: [AuthGuard] },
    { path: 'dashboard-order-completed', component: DashboardOrderCompletedComponent, canActivate: [AuthGuard] },
    { path: 'search-search', component: SearchSearchComponent, canActivate: [AuthGuard] },
    { path: 'overdue-report', component: OverdueReportComponent, canActivate: [AuthGuard] },
    { path: 'search-summary', component: SearchSummaryComponent, canActivate: [AuthGuard] },
    { path: 'ar-report', component: ArReportComponent, canActivate: [AuthGuard] },
    { path: 'invoice-open', component: InvoiceOpenComponent, canActivate: [AuthGuard] },
    { path: 'invoice-all', component: InvoiceAllComponent, canActivate: [AuthGuard] },
    { path: 'teams', component: TeamsComponent, canActivate: [AuthGuard] },
    { path: 'team', component: TeamComponent, canActivate: [AuthGuard] },
    { path: 'user-reset-password', component: UserResetPasswordComponent, canActivate: [AuthGuard] },
    { path: 'user', component: UserComponent, canActivate: [AuthGuard] },
    { path: 'button-demo', component: ButtonDemoComponent, canActivate: [AuthGuard] },
    { path: 'tax-profile-edit', component: TaxProfileEditComponent, canActivate: [AuthGuard] },
    { path: 'agent-order-download', component: AgentOrderDownloadComponent, canActivate: [AuthGuard] },
    { path: 'sys-county', component: SysCountyComponent, canActivate: [AuthGuard]  },
    { path: 'sys-municipality', component: SysMunicipalityComponent, canActivate: [AuthGuard] },
    { path: 'sys-school', component: SysSchoolComponent, canActivate: [AuthGuard] },
    { path: 'order-download-mat', component: OrderDownloadMatComponent, canActivate: [AuthGuard] },
    { path: 'agent-dashboard-mat', component: AgentDashboardMatComponent, canActivate: [AuthGuard] },

    { path: '**', component: LoginComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule]
})
export class AppRoutingModule { }