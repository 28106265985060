import { Component, OnInit } from '@angular/core';
import { ContactsService } from '../services/contacts.service';
import { iContact } from '../model/contact.model';
import { Router } from '@angular/router';
import { MenuItem } from 'primeng/api';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { UserService } from '../services/user.service';


@Component({
  selector: 'app-contacts-primeng',
  templateUrl: './contacts-primeng.component.html',
  styleUrl: './contacts-primeng.component.css'
})
export class ContactsPrimengComponent implements OnInit
{
  contactTableData: iContact[];
  contactColumns =
  [
    { field: 'email', header: 'Email' },
    { field: 'name', header: 'Name' },
    { field: 'companyName', header: 'Company Name' },
    { field: 'relationType', header: 'Relation Type' },
    { field: 'actions', header: 'Actions' }
  ];
  passwordForm: FormGroup = this.fb.group({});
  displayPasswordDialog: boolean = false;
  userUuid: string;
  pagedContacts: iContact[] = [];
  actionItems: MenuItem[] =
  [
    {
      label: 'Options',
      items: [
          {
            label: 'Change Password',
            icon: 'pi pi-key',
            command: () => {
              this.openDialog()
            }
          },
      ]
    }
  ];
  currentPage: number = 0;
  pageSize: number = 10;

  constructor(
    private contactsService: ContactsService,
    private userService: UserService,
    private router: Router,
    private fb: FormBuilder,
    private messageService: MessageService
  ) {}

  ngOnInit()
  {
    this.contactsService.getContacts().subscribe({
      next: (contacts) => {
        this.contactTableData = contacts;
        console.log(contacts)
      },
      error: (err) => {
        console.log('Error while getting contacts')
      }
    })

    this.passwordForm = this.fb.group(
      {
        newPassword: [
          '',
          [
            Validators.required,
            Validators.minLength(8),
            Validators.pattern(/^(?=.*[A-Z])(?=.*\d)[A-Za-z\d@$!%*?&]{8,}$/),
          ],
        ],
        confirmNewPassword: ['', [Validators.required]],
      },
      {
        validators: this.passwordMatchValidator,
      }
    );
  }

  goToContactPage(contact: iContact)
  {
    this.router.navigate(['/contact'], { queryParams: { id: contact.id } });
  }

  onPageChange(event: any)
  {
    this.currentPage = event.page;
    this.pageSize = event.rows;
    this.loadPageData();
  }

  loadPageData()
  {
    const startIndex = this.currentPage * this.pageSize;
    const endIndex = startIndex + this.pageSize;
    this.pagedContacts = this.contactTableData.slice(startIndex, endIndex);
  }

  passwordMatchValidator(group: FormGroup)
  {
    const newPassword = group.get('newPassword')?.value;
    const confirmNewPassword = group.get('confirmNewPassword')?.value;
    return newPassword === confirmNewPassword ? null : { mismatch: true };
  }

  onSubmit() {
    if (this.passwordForm.valid)
    {
      const newPassword = this.passwordForm.value.newPassword;

      const passwordHash = this.userService.hashPassword(newPassword);

      this.userService.changePassword(passwordHash, this.userUuid).subscribe({
        next: () => {
          this.closeDialog();
          this.messageService.add({
            severity: 'success',
            summary: 'Password Changed',
            detail: 'Your password has been changed successfully.',
            life: 3000
          });
        },
        error: (error) => {
          console.error('Error changing password:', error);
          this.messageService.add({
            severity: 'error',
            summary: 'Password Change Failed',
            detail: 'There was an error changing your password. Please try again.',
            life: 3000
          });
        }
      });
    }
  }

  setUserUuid(rowData: iContact)
  {
    this.userUuid = rowData.id
  }

  openDialog()
  {
    this.displayPasswordDialog = true;
  }

  closeDialog()
  {
    this.displayPasswordDialog = false;
    this.passwordForm.reset();
  }
}
