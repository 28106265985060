import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { iClient } from "../model/client.model";

@Injectable({
    providedIn: 'root'
})

export class ClientsService
{
    constructor(private http: HttpClient) 
    { 
        // Nothing to do
    }

    getClientele(parentCompanyUuid: string, relationType: string): Observable<iClient[]>
    {
        return this.http.get<iClient[]>(`api/clientele/${parentCompanyUuid}/${relationType}`);
    }

    getClient(clientUuid: string): Observable<iClient>
    {
        return this.http.get<iClient>(`api/client/${clientUuid}`);
    }

    updateClient(client: iClient)
    {
        return this.http.post<iClient>(`api/clientUpdate`, client);
    }

    createClient(client: iClient)
    {
        return this.http.post<iClient>(`api/clientCreate`, client);
    }

    uploadProfilePicture(file: File)
    {
        const formData = new FormData();
        formData.append('fileKey', file);
      
        return this.http.put<any>('api/uploadProfileFile', formData, {
          reportProgress: true,
          observe: 'events'
        });
    }

    clientMerge(userEmail: string, userCompanyUuid: string, keepCompanyUuid: string, removeCompanyUuid: string)
    {
        const requestBody = {
            userEmail,
            userCompanyUuid,
            keepCompanyUuid,
            removeCompanyUuid
        };
    
        return this.http.post<{ success: boolean; message: string }>(`api/clientMerge`, requestBody);
    }
    
    
    
}