import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { iSalesTax } from "../model/sales-tax.model";



@Injectable({
    providedIn: 'root'
})

export class SalesTaxService
{
    constructor(private http: HttpClient) 
    { 
        // Nothing to do
    }

    getSalesTaxJurisdiction(): Observable<iSalesTax[]>
    {
        return this.http.get<iSalesTax[]>(`api/getSalesTaxJurisdiction/`);
    }
}