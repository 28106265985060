<div class="table-header">
    <h2 class="table-title">Contacts</h2>
    <div class="table-actions">
        <input 
            pInputText 
            type="text" 
            placeholder="Search contacts..." 
            (input)="contactTable.filterGlobal($event.target.value, 'contains')" 
            class="search-input"
        />
    </div>
</div>

<div class="table-container">
    <p-table 
      #contactTable 
      [columns]="contactColumns" 
      [value]="contactTableData" 
      [globalFilterFields]="['email', 'name', 'companyName', 'relationType']" 
      [paginator]="true" 
      [rows]="20" 
      [rowsPerPageOptions]="[10, 20, 30]" 
      (onPageChange)="onPageChange($event)">
      
      <!-- Header -->
      <ng-template pTemplate="header" let-columns>
        <tr>
          <th *ngFor="let col of columns" [pSortableColumn]="col.field !== 'actions' ? col.field : null" class="table-header-cell">
            {{ col.header }}
            <p-sortIcon *ngIf="col.field !== 'actions'" [field]="col.field"></p-sortIcon>
          </th>
        </tr>
      </ng-template>
      
      <!-- Body -->
      <ng-template pTemplate="body" let-rowData let-columns="columns">
        <tr>
          <td *ngFor="let col of columns" class="table-cell">
            <!-- Regular Data Columns -->
            <span *ngIf="col.field !== 'actions'">
              <span *ngIf="col.field === 'name'; else nonClickable" 
                    (click)="goToContactPage(rowData)" 
                    class="clickable-link">
                {{ rowData[col.field] }}
              </span>
              <ng-template #nonClickable>
                {{ rowData[col.field] }}
              </ng-template>
            </span>
            
            <!-- Actions Column -->
            <ng-container *ngIf="col.field === 'actions'">
              <p-menu #menu [model]="actionItems" [popup]="true" appendTo="body"></p-menu>
              <button 
                type="button" 
                pButton 
                icon="pi pi-ellipsis-v" 
                (click)="setUserUuid(rowData); menu.toggle($event)" 
                class="actions-button">
              </button>
          </ng-container>
          </td>
        </tr>
      </ng-template>
    </p-table>
  </div>

  <p-dialog 
  [(visible)]="displayPasswordDialog" 
  [modal]="true" 
  [closable]="false" 
  [header]="'Reset Password'">
  
  <div class="reset-container">
    <form [formGroup]="passwordForm" (ngSubmit)="onSubmit()">
      <div class="form-field">
        <label for="newPassword">New Password</label>
        <input id="newPassword" pInputText type="password" formControlName="newPassword" />
        <small *ngIf="passwordForm.controls['newPassword'].invalid && passwordForm.controls['newPassword'].touched" class="p-error">
          Password must be at least 8 characters long, contain one uppercase letter and one number.
        </small>
      </div>

      <div class="form-field">
        <label for="confirmNewPassword">Confirm New Password</label>
        <input id="confirmNewPassword" pInputText type="password" formControlName="confirmNewPassword" />
        <small *ngIf="passwordForm.errors?.mismatch" class="p-error">
          Passwords do not match.
        </small>
      </div>

      <div class="dialog-footer">
        <button pButton type="button" label="Cancel" severity="danger" class="p-button-text" (click)="closeDialog()"></button>
        <button pButton type="submit" label="Change Password" severity="success" [disabled]="passwordForm.invalid"></button>
      </div>
    </form>
  </div>
</p-dialog>

<p-toast></p-toast>

  