import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { Md5 } from "ts-md5";

@Injectable({
    providedIn: 'root'
})

export class UserService
{
    constructor(private http: HttpClient) 
    { 
        // Nothing to do
    }

    changePassword(newPassword: any, userUuid: string): Observable<any>
    {
        return this.http.post(`api/changePassword`, { newPassword, userUuid });
    }

    hashPassword(password: string)
    {
        if (!password)
        {
            throw new Error("Password cannot be empty.");
        }
        return new Md5().appendStr(password).end();
    }

}