<div class="table-header">
    <h2 class="table-title">Clients</h2>
    <div class="table-actions">
        <input pInputText type="text" placeholder="Search clients..." (input)="clientTable.filterGlobal($event.target.value, 'contains')" class="search-input">
        <button pButton type="button" label="Add Client" icon="pi pi-plus" class="add-client-button" (click)="addClient()"></button>
    </div>
</div>

<div class="table-container">
    <p-table #clientTable [columns]="clientColumns" [value]="clientTableData" [globalFilterFields]="['name', 'email', 'phone']" [paginator]="true" [rows]="20" [rowsPerPageOptions]="[10, 20, 30]" (onPageChange)="onPageChange($event)">
        <ng-template pTemplate="header" let-columns>
            <tr>
                <th *ngFor="let col of columns" pSortableColumn="{{ col.field }}" class="table-header-cell">
                    {{ col.header }}
                    <p-sortIcon [field]="col.field"></p-sortIcon>
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData let-columns="columns">
            <tr>
                <td *ngFor="let col of columns" class="table-cell">
                    <span *ngIf="col.field === 'name'; else nonClickable" (click)="goToClientPage(rowData)" class="clickable-link">
                        {{ rowData[col.field] }}
                    </span>
                    <ng-template #nonClickable>
                        {{ rowData[col.field] }}
                    </ng-template>
                </td>
            </tr>
        </ng-template>
    </p-table>

    <!-- Remove the standalone paginator below -->
</div>
